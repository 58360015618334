@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

// Remove borders and round completely
.fc-button {
border: 0;
border-radius: 20px;
}

.fc-button:focus {
outline: 0;
-webkit-box-shadow: 0 0;
box-shadow: 0 0;
}

// Adjust colors
.fc-button:disabled {
    opacity: 1;
}

.fc-button-primary {
background-color: #2E7599;
}

.fc-button-primary:hover {
background-color: #20506b;
}

.fc-button-primary:focus {
-webkit-box-shadow: 0 0;
box-shadow: 0 0;
}

.fc-button-primary:disabled {
color: rgba(0, 0, 0, 0.26);
background-color: rgba(0,0,0,0.12);
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
background-color: #20506b;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
-webkit-box-shadow: 0 0;
box-shadow: 0 0;
}

// Align buttons
.fc-left, .fc-right {
    flex-grow: 1;
    flex-basis: 0;
}

.fc-center {
    flex-grow: 0;
}

.fc-right > * {
    float: right;
}

// Adjust font weights
h2 {
    font-weight: normal;
}

.fc-day-header {
    font-weight: normal;
}
